<script lang="ts">
	import { override } from '$lib/utils/classnames'
	import { twMerge } from 'tailwind-merge'
	import MainTitle from './MainTitle.svelte'
	import type { Snippet } from 'svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		class?: string
		buttonClass?: string
		isIntersecting?: boolean
		title?: string
		onclick?: () => void
		titleSlot?: Snippet
		children?: Snippet
	}

	let {
		class: className = '',
		buttonClass = '',
		isIntersecting = true,
		title = '',
		onclick,
		titleSlot,
		children,
	}: Props = $props()

	function handleClick() {
		onclick?.()
	}

	let computedClassName = $derived(
		twMerge(
			override(
				'flex items-center justify-between sticky top-[64px] lg:top-[116px] duration-200 transition-shadow transform-gpu will-change-transition pt-7 pb-4 h-7 lg:h-10 lg:pt-10 lg:pb-5 sm:px-0 z-10 border-b bg-white dark:bg-brand-gray-5 border-brand-gray-2 text-black dark:border-brand-gray-4/20 dark:text-white',
				className,
			),
		),
	)

	let computedButtonClassName = $derived(
		twMerge(
			override(
				'cursor-pointer text-brand-gray-5 hover:text-black dark:hover:text-white dark:text-brand-gray-2 transition-colors touch-manipulation',
				buttonClass,
			),
		),
	)
</script>

<div class={override(`${isIntersecting ? 'sticky-header-shadow' : ''}`, computedClassName)}>
	{#if title}
		<button class={computedButtonClassName} onclick={stopPropagation(handleClick)}>
			<MainTitle {title} />
		</button>
	{:else}
		<MainTitle>
			{@render titleSlot?.()}
		</MainTitle>
	{/if}
	{@render children?.()}
</div>
