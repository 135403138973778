<script lang="ts">
	import { override } from '$lib/utils/classnames'
	import { fixTitleCharacters } from '$lib/utils/title-case'


	interface Props {
		title?: string;
		class?: string;
		children?: import('svelte').Snippet;
	}

	let { title = '', class: className = '', children }: Props = $props();
	export const as = 'span'
	
</script>

<div
	class={override(
		'text-sm font-normal tracking-wide leading-4 sm:text-base xl:text-lg 2xl:text-lg ',
		className,
	)}
>
	{#if children}{@render children()}{:else}{fixTitleCharacters(title)}{/if}
</div>
